/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon } from '@hospy/hospy-ui';
import React, { useCallback, useMemo } from 'react';
import { createEditor, Editor, Text, Transforms } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import {
	BlockButton,
	MarkButton,
	RenderElement,
	RenderLeaf,
	serialize,
	Sidebar,
	SidebarContent,
	SidebarTitle,
	SlateContainer,
	SlateContent,
	SlateDisabled,
	Toolbar
} from './components';
import { Tooltip } from 'antd';

const withVariables = (editor: any) => {
	const { isInline } = editor;
	editor.isInline = (element: any) => {
		return element.type === 'variable' ? true : isInline(element);
	};
	return editor;
};

interface RichTextProps {
	onChange: (string: any, rawJson: any) => void;
	value?: any;
	placeholder?: string;
	codes?: { label: string; value: string; info?: string }[];
	renderSidebarHeader?: React.ReactNode;
	title?: React.ReactNode;
	renderFooter?: React.ReactNode;
	disabled?: boolean;
	style?: React.CSSProperties;
	hiddenToolbarItems?: string[];
	hiddenToolbar?: boolean;
	sidebarStyle?: React.CSSProperties;
	sidebarTitle?: string;
	maxLength?: number;
}

export const RichText: React.FC<RichTextProps> = ({
	onChange,
	value,
	placeholder,
	codes,
	renderSidebarHeader,
	title,
	renderFooter,
	disabled,
	style,
	hiddenToolbarItems,
	sidebarStyle,
	sidebarTitle,
	maxLength,
	hiddenToolbar
}) => {
	const editor = useMemo(() => withVariables(withReact(createEditor())), []);

	const renderElement = useCallback(
		(props: any) => <RenderElement {...props} />,
		[]
	);
	const renderLeaf = useCallback(
		(props: any) => <RenderLeaf {...props} />,
		[]
	);

	const insertVariable = (variable: string) => {
		Transforms.insertText(editor, variable);
	};

	const codePattern = new RegExp(
		(codes || []).map((code) => code.label).join('|'),
		'g'
	);

	const handleDOMBeforeInput = (event: any) => {
		if (maxLength) {
			const inputType = event.inputType;
			if (inputType === 'insertText') {
				const textLength = Editor.string(editor, []).length;
				if (textLength >= maxLength) {
					event.preventDefault();
					return;
				}
			}
		}
	};

	const decorate = useCallback(([node, path]: any) => {
		const ranges = [];
		if (codes && codes?.length && Text.isText(node)) {
			const { text } = node;
			let match;
			while ((match = codePattern.exec(text)) !== null) {
				const start = match.index;
				const end = start + match[0].length;
				ranges.push({
					anchor: { path, offset: start },
					focus: { path, offset: end },
					code: true
				});
			}
		}
		return ranges;
	}, []);

	return (
		<Slate
			editor={editor}
			value={value}
			onChange={(value) => {
				onChange(`<div>${serialize(value).join('')}</div>`, value);
			}}
		>
			<SlateContainer>
				<SlateContent
					style={{
						border: 0,
						paddingBlock: 30,
						paddingInline: 15,
						paddingBottom: 15,
						...style
					}}
				>
					{title}
					<SlateContent>
						{disabled && <SlateDisabled />}
						{!hiddenToolbar && (
							<Toolbar>
								{!hiddenToolbarItems?.includes(
									'heading-one'
								) && (
									<BlockButton
										format="heading-one"
										icon="format_h1"
									/>
								)}
								{!hiddenToolbarItems?.includes(
									'heading-two'
								) && (
									<BlockButton
										format="heading-two"
										icon="format_h2"
									/>
								)}
								{!hiddenToolbarItems?.includes('bold') && (
									<MarkButton
										format="bold"
										icon="format_bold"
										iconStyle={{ width: 20 }}
									/>
								)}
								{!hiddenToolbarItems?.includes('italic') && (
									<MarkButton
										format="italic"
										icon="format_italic"
										iconStyle={{ width: 20 }}
									/>
								)}
								{!hiddenToolbarItems?.includes(
									'numbered-list'
								) && (
									<BlockButton
										format="numbered-list"
										icon="format_list_numbered"
										iconStyle={{ width: 18 }}
									/>
								)}
								{!hiddenToolbarItems?.includes(
									'bulleted-list'
								) && (
									<BlockButton
										format="bulleted-list"
										icon="format_list_bulleted"
										iconStyle={{ width: 18 }}
									/>
								)}
							</Toolbar>
						)}

						<Editable
							disabled={disabled}
							style={{ minHeight: 300, padding: 15 }}
							renderElement={renderElement}
							renderLeaf={renderLeaf}
							placeholder={placeholder || ''}
							spellCheck
							decorate={decorate}
							onDOMBeforeInput={handleDOMBeforeInput}
						/>
					</SlateContent>
					{renderFooter}
				</SlateContent>
				{codes && (
					<Sidebar style={sidebarStyle}>
						<SidebarContent>
							{renderSidebarHeader}
							<SidebarTitle>
								{sidebarTitle || 'Códigos insertables:'}
							</SidebarTitle>
							{codes.map((e) => (
								<Button
									block
									key={e.value}
									onClick={() => void insertVariable(e.label)}
									disabled={disabled}
								>
									{e.label}
									{e.info && (
										<Tooltip title={e.info}>
											<Icon
												materialOutlined="info"
												style={{
													position: 'absolute',
													right: '15px',
													marginTop: '2px'
												}}
											/>
										</Tooltip>
									)}
								</Button>
							))}
						</SidebarContent>
					</Sidebar>
				)}
			</SlateContainer>
		</Slate>
	);
};
